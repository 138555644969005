import React        from 'react'
import clsx         from 'clsx'
import {makeStyles} from '@material-ui/core/styles'
import Drawer       from '@material-ui/core/Drawer'
import Button       from '@material-ui/core/Button'
import List         from '@material-ui/core/List'
import ListItem     from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon    from '@material-ui/icons/MoveToInbox'
import MailIcon     from '@material-ui/icons/Mail'
import {Grid}       from '@material-ui/core'
import {Link}       from 'gatsby'

const useStyles = makeStyles({
  list: {
    width: '100%'
  },
  fullList: {
    width: 'auto'
  },
  link: {
    textDecoration: 'none',
    color: '#7B7675'
  }
})

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface NavDrawerProps {
  menuItems: {title: string, route: string}[]
  navDrawerPosition: Anchor
}

const NavDrawer = (props: NavDrawerProps) => {

  const {menuItems, navDrawerPosition} = props

  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({...state, [anchor]: open})
  }

    const listClassName = (stylez: Record<string, string>, anch: Anchor) => {
        return `${stylez.list} ${anch === 'top' || anch === 'bottom' ? classes.fullList : ''}`
    }

  const renderList = (anchor: Anchor) => (
    <div
      className={listClassName(classes, anchor)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {menuItems.map((menuItem, index) => (
          <Link key={index} to={`/${menuItem.route}`} className={classes.link}>
            <ListItem button>
              {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}</ListItemIcon>*/}
              <ListItemText primary={menuItem.title}/>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )

  return (
    <React.Fragment>
      <Grid container justify={'flex-end'}>
        <Grid item>
          <Button color={'primary'} onClick={toggleDrawer(navDrawerPosition, true)}>Menu</Button>
        </Grid>
      </Grid>
      <Drawer anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
        {renderList('top')}
      </Drawer>
    </React.Fragment>
  )
}

export default NavDrawer
