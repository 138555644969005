import React from 'react'

type StructuredDataProps = {
    data: any
}

const StructuredData = (props: StructuredDataProps) => <script
    dangerouslySetInnerHTML={{ __html: JSON.stringify(props.data) }} type={'application/ld+json'} />

export default StructuredData
