export const level22OrganisationData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Sydney, Australia',
        'postalCode': '2000',
        'streetAddress': '52 Martin Place'
    },
    'email': 'clerk@level22.com.au',
    'faxNumber': '+61 02 9335 3500',
    'name': 'Level 22 Chambers',
    'telephone': '+61 02 9151 2222'
}

const MG = {
    'name': 'Michael Green SC',
    'description': 'Barrister at Level 22 Chambers in Sydney, Australia.',
    'identifier': 'https://level22.com.au/michael-green-sc',
    'url': 'https://level22.com.au/michael-green-sc',
    'parentOrganisation': 'Level 22 Chambers',
    'knowsAbout': [' Public Law', ' Tort Law', ' Intellectual Property', ', and Commercial Law'],
    'knowsLanguage': 'English',
    'makesOffer': { 'businessFunction': 'gr:ProvideService' },
    '@context': 'https://schema.org',
    '@type': 'LegalService',
    'address': {
        '@type': 'PostalAddress',
        'addressLocality': 'Sydney, Australia',
        'postalCode': '2000',
        'streetAddress': '52 Martin Place'
    },
    'openingHours': ['Mo-Fr 08:30-17:00'],
    'contactPoint': {
        'name': 'Clerk for Michael Green SC',
        'email': 'clerk@level22.com.au',
        'faxNumber': '+61 02 9335 3500',
        'telephone': '+61 02 9151 2222',
        'productSupported': 'Legal advice and representation'
    },
    'organisation': 'Level 22 Chambers',
    'telephone': '+61 02 9151 2222 '
}

