import {createTheme} from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            light: "#6d6d6d",
            main: "#424242",
            dark: "#1b1b1b"
        },
        secondary: {
            light: "#ffffff",
            main: "#fafafa",
            dark: "#c7c7c7"
        }
    },
    overrides: {
        MuiChip: { // The key for the Chip component
            root: {
                // backgroundColor: "#333",
                // background: "none",
                // borderColor: "#ffffff",
                // borderStyle: "solid",
                // borderWidth: "0.5px",
                // color: "#ffffff",
                // position: "relative",
                // margin: "8px 8px 8px 0px"
            }
        }
    },
    typography: {
        fontFamily: "Calibre, sans-serif",
    }
});

theme.typography.h1 = {
    fontSize: '3.00rem',
    '@media (min-width:600px)': {
        fontSize: '3.5rem'
    },
    fontWeight: 500,
    textAlign: 'center'
}
//
//
// theme.typography.h2 = {
//     fontSize: '3.75rem',
//     '@media (min-width:600px)': {
//         fontSize: '1.75rem'
//     }
// }
//
// theme.typography.h5 = {
//     fontSize: '1.75rem',
//     [theme.breakpoints.down('xs')]: {
//         fontSize: '1rem'
//     }
// }
export default theme;
