import React                                        from 'react'
import {AppBar, Grid, Hidden, Link, Theme, Toolbar} from '@material-ui/core'
import {graphql, StaticQuery}                       from 'gatsby'
import theme                                        from '../../theme'
import {makeStyles}                                 from '@material-ui/styles'
import NavDrawer                                    from './NavDrawer'
import SkinSideUp                                   from '../../assets/textures/skin-side-up.png'
import SanityImage                                  from 'gatsby-plugin-sanity-image';

interface NavMenuProps {
    theme?: Theme
}

const useStyles = makeStyles((theme: Theme) => ({
            root: {
                background: '#FFF',
                // backgroundImage: `url(${HorizontalLight})`,
                backgroundImage: `url(${SkinSideUp})`,
                height: '120px'
            },
            menuItem: {
                textTransform: 'uppercase',
                color: `#94796F`,
                fontSize: '18px',
                fontWeight: 600,
                '&:hover': {
                    textDecoration: 'underline'
                }
            },
            brand: {
                color: '#FFF'
            },
            logo: {
                width: '100%'
            },
            toolbar: {
                height: '100%'
            },
            menuItemWrapper: {
                display: 'grid',
                fontSize: '2rem'
            }
        }
    )
)

const NavMenu = (props: NavMenuProps) => {
    const classes = useStyles(theme)

    const menuItems = [
        {
            title: 'Home',
            route: ''
        },
        {
            title: 'Barristers',
            route: 'barristers'
        },
        {
            title: 'Clerks',
            route: 'clerks'
        },
        {
            title: 'Practice Areas',
            route: 'practice-areas'
        },
        {
            title: 'Events + Seminars',
            route: 'events'
        },
        {
            title: 'News',
            route: 'news'
        },
        {
            title: 'Contact',
            route: 'contact'
        }]
    return (
        <StaticQuery query={graphql`
                                    query MyQuery {
                                      sanitySiteSettings {
                                        siteMedia {
                                          navbarBrand {
                                            ...MainImage
                                          }
                                        }
                                      }
                                    }
                                    `}
                     render={data => (
                         /*
                                                  <Headroom upTolerance={1}>
                         */
                         <AppBar className={classes.root} color="secondary" position={'static'}>
                             <Toolbar className={classes.toolbar}>
                                 <Hidden smDown>
                                     <Grid container spacing={6} justify={'center'} alignItems={'center'}
                                           alignContent={'center'}>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/barristers">Barristers</Link>
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/clerks">Clerks</Link>
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/practice-areas">Practice
                                                 Areas</Link>
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item xs={3}>
                                             {data.sanitySiteSettings.siteMedia.navbarBrand && (
                                                 <Link style={{display: 'flex', justifyContent: 'center'}} href="/">
                                                     <SanityImage
                                                         style={{
                                                             maxWidth: '400px',
                                                             objectFit: 'cover',
                                                             width: '100%',
                                                         }}
                                                         {...data.sanitySiteSettings.siteMedia.navbarBrand}
                                                         title={'Level 22 Logo'}/>
                                                 </Link>
                                             )}
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/events">Events +
                                                 Seminars</Link>
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/news">News</Link>
                                         </Grid>
                                         <Grid className={classes.menuItemWrapper} item>
                                             <Link className={classes.menuItem} href="/contact">Contact</Link>
                                         </Grid>
                                     </Grid>
                                 </Hidden>
                                 <Hidden mdUp>
                                     <Grid item xs={8} sm={6} md={4} className={classes.brand}>
                                         <Link href="/">
                                             <SanityImage
                                                 style={{maxWidth: 350}}
                                                 {...data.sanitySiteSettings.siteMedia.navbarBrand}
                                                 title={'Level 22 Logo'}/>
                                         </Link>
                                     </Grid>
                                     <Grid item xs={6} sm={6} md={8}>
                                         <NavDrawer
                                             navDrawerPosition={'top'}
                                             menuItems={menuItems}/>
                                     </Grid>
                                 </Hidden>
                             </Toolbar>
                         </AppBar>
                         /*
                                                  </Headroom>
                         */
                     )}/>
    );
}

export default NavMenu
